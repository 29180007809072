//This directive displays a table of documents and a add button for adding to specified document_x_object with correct column data.
(function () {

	var app = angular.module('Plania');

	app.directive('dynamicProperties', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=model',
				isCollapsed: '=isCollapsed',
				guidProperty: '=guidProperty',
				newDynamicProperty: '=newDynamicProperty',
				autoCompleteProperty: '=autoCompleteProperty',
				viewMode: '=viewMode',
				restrictEdit: '=restrictEdit',
				delayMandatoryFields: '=delayMandatoryFields'
			},
			controller: ['$scope', '$localStorage', '$modal', '$filter', '$sce', 'DynamicPropertyService', '$rootScope', controller],
			templateUrl: 'app/common/directives/dynamicProperty/dynamicPropertyDirective.html'
		};
	});

	function controller($scope, $localStorage, $modal, $filter, $sce, dynamicPropertyService, $rootScope) {
		$scope.isExternalUser = $localStorage.userData.isExternalUser;
		$scope.isCollapsed = $localStorage.dynamicPropertiesIsCollapsed;
		$scope.disabledFields = {};

		$scope.toggleCollapsed = function () {
			$scope.isCollapsed = !$scope.isCollapsed;
			$localStorage.dynamicPropertiesIsCollapsed = $scope.isCollapsed;
		};

		$scope.getClassStyle = function (property, isLast, properties) {
			if (property.Type === 'Category') return 'dynamic-property-item-width-12';

			var classes = ['dynamic-property-item'];

			if (property.WebLayout) {
				if (property.WebLayout.Width === 12)
					classes.push('dynamic-property-item-width-12');
				else if (property.WebLayout.Width === 8)
					classes.push('dynamic-property-item-width-8');
			}

			// Apply helper css class to the last element of a 3 row, or when row contains a 8 column property.
			if (isLast && properties.length === 3) {
				classes.push('dynamic-property-item-wrap-fix');
			} else if (properties.length === 2) {
				var mediumWidthProp = properties.find(function (prop) {
					return prop.WebLayout && prop.WebLayout.Width === 8;
				});
				if (mediumWidthProp && property !== mediumWidthProp) {
					classes.push('dynamic-property-item-wrap-fix');
				}
			}

			return classes.join(" ");
		};

		$scope.openHelpModal = function (text) {
			$modal.open({
				templateUrl: 'app/common/directives/dynamicProperty/dynamicPropertyHelpModal.html',
				controller: 'DynamicPropertyHelpController',
				size: 'm',
				resolve: {
					params: function () {
						return {
							text: text,
						};
					}
				}
			});
		};

		var getItems = function () {
			if (!$scope.model || !$scope.model.DynamicProperty || !$scope.model.DynamicProperty.Groups) return [];
			return dynamicPropertyService.getItems($scope.model.DynamicProperty.Groups);
		};

		var reinitDynamicFieldDefaultValues = function () {
			getItems().forEach(function (item) {
				if (item.Type === 'Combo') {
					var selectedOption = _.find(item.NamedSelectionOptions, function (option) {
						return option === $scope.model[item.PropertyName];
					});

					if (!selectedOption) {
						if (item.NamedSelectionDefaultOption)
							$scope.model[item.PropertyName] = item.NamedSelectionDefaultOption;
						else {
							$scope.model[item.PropertyName] = null;
						}
					}
				}
			});
		};

		var oldPropertyValue = $scope.model[$scope.guidProperty];
		var oldAutoCompleteValue = $scope.autoCompleteProperty;

		$scope.getPropertyValue = function(property)		{
			var value = $scope.model[property.PropertyName];
			if (!value) return value;
			if (property.Type === 'Date') return $filter('date')(value, 'dd.MM.yyyy');
			else if (property.Type === 'Combo' && property.NamedSelectionMultiValue && value.startsWith('[') && value.endsWith(']')) {
				
				var list = JSON.parse(value);
				var html = "<div class ='chosen-container chosen-container-multi'>";
				html += "<ul class='chosen-choices chosen-choices-readonly'>";
				_.forEach(list, function(item){
					html += "<li class='search-choice search-choice-readonly' >" + $sce.getTrustedHtml(item) + "</li>";
				});
				html += "</ul>";
				html += "</div>";
				return $sce.trustAsHtml(html);
			}
			else {
				return value;
			}
		};

		var updateDisabledFields = function () {
			var disabledFields = {};

			// Only for KA and is on building
			if (!$rootScope.customization.isCustomer('KA') || $scope.guidProperty !== 'GuidBuildingCategory') {
				$scope.disabledFields = disabledFields;
				return;
			}

			if (!$scope.model.ExternalId) {
				$scope.disabledFields = disabledFields;
				return;
			}

			disabledFields = {
				Text01: true,
				Text07: true,
				Text11: true,
				Text13: true,
				Text14: true,
				Combo01: true,
				Combo12: true,
				Number05: true,
				Number06: true,
				Number07: true,
			};
			
			$scope.disabledFields = disabledFields;
		};

		if ($rootScope.customization.isCustomer('KA') && $scope.guidProperty === 'GuidBuildingCategory') {
			$scope.$watch('model.ExternalId', function () {
				updateDisabledFields();
			});
		}

		$scope.$watch('model.' + $scope.guidProperty,
			function (newValue, oldValue) {
				if (newValue === oldValue) return;
				if (newValue === oldPropertyValue) return;
				if (oldPropertyValue === undefined) {
					// either existing entity is just being loaded, or category is just being set for the first time on a new entity
					oldPropertyValue = newValue;
					oldAutoCompleteValue = $scope.autoCompleteProperty;
					// reinit fields only if the entity did not have a valid category (=new entity)
					if (!$scope.model.DynamicProperty || $scope.model.DynamicProperty.Groups.length <= 0) {
						$scope.model.DynamicProperty = $scope.newDynamicProperty;
						reinitDynamicFieldDefaultValues();
						updateDisabledFields();
                    }
                    return;
				}

				if ($scope.model.DynamicProperty && $scope.model.DynamicProperty.Groups.length > 0) {
					$modal.open({
						templateUrl: 'app/common/views/confirmationBox.html',
						controller: 'ConfirmationBoxController',
						resolve: {
							confirmation: function () {
								return {
									header: "Bekreft handling",
									message:
										"Du endrer en kategori som har egendefinerte felter, ønsker du å fortsette?"
								};
							}
						}
					}).result.then(function () {
						oldPropertyValue = newValue;
						oldAutoCompleteValue = $scope.autoCompleteProperty;
						$scope.model.DynamicProperty = $scope.newDynamicProperty;
						reinitDynamicFieldDefaultValues();
						updateDisabledFields();
					}, function () {
						$scope.model[$scope.guidProperty] = oldPropertyValue;
						$scope.autoCompleteProperty = oldAutoCompleteValue;
					});
				} else {
					oldPropertyValue = newValue;
					oldAutoCompleteValue = $scope.autoCompleteProperty;
					$scope.model.DynamicProperty = $scope.newDynamicProperty;
					reinitDynamicFieldDefaultValues();
					updateDisabledFields();
				}
			});
	}

	app.controller('DynamicPropertyHelpController', ['$scope', 'params', '$modalInstance', function ($scope, params, $modalInstance) {
		$scope.text = params.text;

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}]);
})();
